import { default as consort_45differencerUmRYMMCoGMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/consort-difference.vue?macro=true";
import { default as indexubXjXv4FYOMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue?macro=true";
import { default as indexSbrEHx6iKrMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue?macro=true";
import { default as indexSYtxnq23KFMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue?macro=true";
import { default as indexSrKBm23jasMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/events/index.vue?macro=true";
import { default as our_45processNX62Sb2mNIMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/our-process.vue?macro=true";
import { default as our_45storykyUb8ak5VLMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/our-story.vue?macro=true";
import { default as our_45teamX71ZHQmi5mMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/our-team.vue?macro=true";
import { default as why_45consortiCLyG8BY5fMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/why-consort.vue?macro=true";
import { default as callbackRL6I2BK95uMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/callback.vue?macro=true";
import { default as _91_46_46_46slug_93wpSWjtd68TMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexc1jvbVr5qYMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as career_45opportunitiesajx1hZBXz2Meta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as contact_45usLL3l8x33IFMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue?macro=true";
import { default as for_45realtorsdZgdBnfFPJMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue?macro=true";
import { default as our_45locationsx4T0K8oto1Meta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as request_45an_45appointmentVez7NRHRBsMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue?macro=true";
import { default as join_45vip_45list9gXVNQqjwXMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue?macro=true";
import { default as why_45buy_45nowxaUIHTWqNHMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue?macro=true";
import { default as indexdzm3QKY7NqMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue?macro=true";
import { default as index2oGd8adurpMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue?macro=true";
import { default as indexnlMsLL1iIJMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue?macro=true";
import { default as indexOaDpKO0I3sMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue?macro=true";
import { default as indexJsZ8tzIoDaMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue?macro=true";
import { default as index39JmjTs7kpMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/video/index.vue?macro=true";
import { default as indexLZ0vUYjGYgMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue?macro=true";
import { default as indexzVvmI44qSFMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/index.vue?macro=true";
import { default as interactive_45home_45designd5gJrPZMaWMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/interactive-home-design.vue?macro=true";
import { default as my_45favoritesrDyk91cYgHMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as indexr1S7aJVViyMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue?macro=true";
import { default as indexUUsWr6p9lKMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue?macro=true";
import { default as indexOKUosattcsMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue?macro=true";
import { default as build_45on_45your_45lotR0IwpJX1z2Meta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue?macro=true";
import { default as design_45processkJCmKNE8lFMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue?macro=true";
import { default as financingNhcgg3oJQDMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/our-homes/financing.vue?macro=true";
import { default as indexxGjJq6ngCrMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacy_45policyQfPFmAQcDfMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_930vqdcXDqfmMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue?macro=true";
import { default as indexV1X6UliDEuMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/promotions/index.vue?macro=true";
import { default as sandbox3Q6mSlygTEMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as self_45guided_45home_45toursbJwMAHIHDZMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue?macro=true";
import { default as _91testimonial_93SiBjzEc3jPMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue?macro=true";
import { default as where_45we_45buildkExnSBHJueMeta } from "/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/where-we-build.vue?macro=true";
export default [
  {
    name: consort_45differencerUmRYMMCoGMeta?.name ?? "about-consort-difference",
    path: consort_45differencerUmRYMMCoGMeta?.path ?? "/about/consort-difference",
    meta: consort_45differencerUmRYMMCoGMeta || {},
    alias: consort_45differencerUmRYMMCoGMeta?.alias || [],
    redirect: consort_45differencerUmRYMMCoGMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/consort-difference.vue").then(m => m.default || m)
  },
  {
    name: indexubXjXv4FYOMeta?.name ?? "about-consort-homes-reviews-and-testimonials-category-category",
    path: indexubXjXv4FYOMeta?.path ?? "/about/consort-homes-reviews-and-testimonials/category/:category()",
    meta: indexubXjXv4FYOMeta || {},
    alias: indexubXjXv4FYOMeta?.alias || [],
    redirect: indexubXjXv4FYOMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSbrEHx6iKrMeta?.name ?? "about-consort-homes-reviews-and-testimonials",
    path: indexSbrEHx6iKrMeta?.path ?? "/about/consort-homes-reviews-and-testimonials",
    meta: indexSbrEHx6iKrMeta || {},
    alias: indexSbrEHx6iKrMeta?.alias || [],
    redirect: indexSbrEHx6iKrMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: indexSYtxnq23KFMeta?.name ?? "about-events-event",
    path: indexSYtxnq23KFMeta?.path ?? "/about/events/:event()",
    meta: indexSYtxnq23KFMeta || {},
    alias: indexSYtxnq23KFMeta?.alias || [],
    redirect: indexSYtxnq23KFMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSrKBm23jasMeta?.name ?? "about-events",
    path: indexSrKBm23jasMeta?.path ?? "/about/events",
    meta: indexSrKBm23jasMeta || {},
    alias: indexSrKBm23jasMeta?.alias || [],
    redirect: indexSrKBm23jasMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/events/index.vue").then(m => m.default || m)
  },
  {
    name: our_45processNX62Sb2mNIMeta?.name ?? "about-our-process",
    path: our_45processNX62Sb2mNIMeta?.path ?? "/about/our-process",
    meta: our_45processNX62Sb2mNIMeta || {},
    alias: our_45processNX62Sb2mNIMeta?.alias || [],
    redirect: our_45processNX62Sb2mNIMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/our-process.vue").then(m => m.default || m)
  },
  {
    name: our_45storykyUb8ak5VLMeta?.name ?? "about-our-story",
    path: our_45storykyUb8ak5VLMeta?.path ?? "/about/our-story",
    meta: our_45storykyUb8ak5VLMeta || {},
    alias: our_45storykyUb8ak5VLMeta?.alias || [],
    redirect: our_45storykyUb8ak5VLMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/our-story.vue").then(m => m.default || m)
  },
  {
    name: our_45teamX71ZHQmi5mMeta?.name ?? "about-our-team",
    path: our_45teamX71ZHQmi5mMeta?.path ?? "/about/our-team",
    meta: our_45teamX71ZHQmi5mMeta || {},
    alias: our_45teamX71ZHQmi5mMeta?.alias || [],
    redirect: our_45teamX71ZHQmi5mMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/our-team.vue").then(m => m.default || m)
  },
  {
    name: why_45consortiCLyG8BY5fMeta?.name ?? "about-why-consort",
    path: why_45consortiCLyG8BY5fMeta?.path ?? "/about/why-consort",
    meta: why_45consortiCLyG8BY5fMeta || {},
    alias: why_45consortiCLyG8BY5fMeta?.alias || [],
    redirect: why_45consortiCLyG8BY5fMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/about/why-consort.vue").then(m => m.default || m)
  },
  {
    name: callbackRL6I2BK95uMeta?.name ?? "callback",
    path: callbackRL6I2BK95uMeta?.path ?? "/callback",
    meta: callbackRL6I2BK95uMeta || {},
    alias: callbackRL6I2BK95uMeta?.alias || [],
    redirect: callbackRL6I2BK95uMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wpSWjtd68TMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93wpSWjtd68TMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93wpSWjtd68TMeta || {},
    alias: _91_46_46_46slug_93wpSWjtd68TMeta?.alias || [],
    redirect: _91_46_46_46slug_93wpSWjtd68TMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexc1jvbVr5qYMeta?.name ?? "campaigns",
    path: indexc1jvbVr5qYMeta?.path ?? "/campaigns",
    meta: indexc1jvbVr5qYMeta || {},
    alias: indexc1jvbVr5qYMeta?.alias || [],
    redirect: indexc1jvbVr5qYMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesajx1hZBXz2Meta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesajx1hZBXz2Meta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesajx1hZBXz2Meta || {},
    alias: career_45opportunitiesajx1hZBXz2Meta?.alias || [],
    redirect: career_45opportunitiesajx1hZBXz2Meta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: contact_45usLL3l8x33IFMeta?.name ?? "contact-us-contact-us",
    path: contact_45usLL3l8x33IFMeta?.path ?? "/contact-us/contact-us",
    meta: contact_45usLL3l8x33IFMeta || {},
    alias: contact_45usLL3l8x33IFMeta?.alias || [],
    redirect: contact_45usLL3l8x33IFMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue").then(m => m.default || m)
  },
  {
    name: for_45realtorsdZgdBnfFPJMeta?.name ?? "contact-us-for-realtors",
    path: for_45realtorsdZgdBnfFPJMeta?.path ?? "/contact-us/for-realtors",
    meta: for_45realtorsdZgdBnfFPJMeta || {},
    alias: for_45realtorsdZgdBnfFPJMeta?.alias || [],
    redirect: for_45realtorsdZgdBnfFPJMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsx4T0K8oto1Meta?.name ?? "contact-us-our-locations",
    path: our_45locationsx4T0K8oto1Meta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsx4T0K8oto1Meta || {},
    alias: our_45locationsx4T0K8oto1Meta?.alias || [],
    redirect: our_45locationsx4T0K8oto1Meta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: request_45an_45appointmentVez7NRHRBsMeta?.name ?? "contact-us-request-an-appointment",
    path: request_45an_45appointmentVez7NRHRBsMeta?.path ?? "/contact-us/request-an-appointment",
    meta: request_45an_45appointmentVez7NRHRBsMeta || {},
    alias: request_45an_45appointmentVez7NRHRBsMeta?.alias || [],
    redirect: request_45an_45appointmentVez7NRHRBsMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue").then(m => m.default || m)
  },
  {
    name: join_45vip_45list9gXVNQqjwXMeta?.name ?? "current-offers-join-vip-list",
    path: join_45vip_45list9gXVNQqjwXMeta?.path ?? "/current-offers/join-vip-list",
    meta: join_45vip_45list9gXVNQqjwXMeta || {},
    alias: join_45vip_45list9gXVNQqjwXMeta?.alias || [],
    redirect: join_45vip_45list9gXVNQqjwXMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue").then(m => m.default || m)
  },
  {
    name: why_45buy_45nowxaUIHTWqNHMeta?.name ?? "current-offers-why-buy-now",
    path: why_45buy_45nowxaUIHTWqNHMeta?.path ?? "/current-offers/why-buy-now",
    meta: why_45buy_45nowxaUIHTWqNHMeta || {},
    alias: why_45buy_45nowxaUIHTWqNHMeta?.alias || [],
    redirect: why_45buy_45nowxaUIHTWqNHMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue").then(m => m.default || m)
  },
  {
    name: indexdzm3QKY7NqMeta?.name ?? "display-homes-model",
    path: indexdzm3QKY7NqMeta?.path ?? "/display-homes/:model()",
    meta: indexdzm3QKY7NqMeta || {},
    alias: indexdzm3QKY7NqMeta?.alias || [],
    redirect: indexdzm3QKY7NqMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: index2oGd8adurpMeta?.name ?? "gallery-heritage-gallery",
    path: index2oGd8adurpMeta?.path ?? "/gallery/heritage-gallery",
    meta: index2oGd8adurpMeta || {},
    alias: index2oGd8adurpMeta?.alias || [],
    redirect: index2oGd8adurpMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexnlMsLL1iIJMeta?.name ?? "gallery-hometown-gallery",
    path: indexnlMsLL1iIJMeta?.path ?? "/gallery/hometown-gallery",
    meta: indexnlMsLL1iIJMeta || {},
    alias: indexnlMsLL1iIJMeta?.alias || [],
    redirect: indexnlMsLL1iIJMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexOaDpKO0I3sMeta?.name ?? "gallery-photos",
    path: indexOaDpKO0I3sMeta?.path ?? "/gallery/photos",
    meta: indexOaDpKO0I3sMeta || {},
    alias: indexOaDpKO0I3sMeta?.alias || [],
    redirect: indexOaDpKO0I3sMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue").then(m => m.default || m)
  },
  {
    name: indexJsZ8tzIoDaMeta?.name ?? "gallery-product-gallery",
    path: indexJsZ8tzIoDaMeta?.path ?? "/gallery/product-gallery",
    meta: indexJsZ8tzIoDaMeta || {},
    alias: indexJsZ8tzIoDaMeta?.alias || [],
    redirect: indexJsZ8tzIoDaMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: index39JmjTs7kpMeta?.name ?? "gallery-video",
    path: index39JmjTs7kpMeta?.path ?? "/gallery/video",
    meta: index39JmjTs7kpMeta || {},
    alias: index39JmjTs7kpMeta?.alias || [],
    redirect: index39JmjTs7kpMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/video/index.vue").then(m => m.default || m)
  },
  {
    name: indexLZ0vUYjGYgMeta?.name ?? "gallery-virtual-tours",
    path: indexLZ0vUYjGYgMeta?.path ?? "/gallery/virtual-tours",
    meta: indexLZ0vUYjGYgMeta || {},
    alias: indexLZ0vUYjGYgMeta?.alias || [],
    redirect: indexLZ0vUYjGYgMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexzVvmI44qSFMeta?.name ?? "index",
    path: indexzVvmI44qSFMeta?.path ?? "/",
    meta: indexzVvmI44qSFMeta || {},
    alias: indexzVvmI44qSFMeta?.alias || [],
    redirect: indexzVvmI44qSFMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: interactive_45home_45designd5gJrPZMaWMeta?.name ?? "interactive-home-design",
    path: interactive_45home_45designd5gJrPZMaWMeta?.path ?? "/interactive-home-design",
    meta: interactive_45home_45designd5gJrPZMaWMeta || {},
    alias: interactive_45home_45designd5gJrPZMaWMeta?.alias || [],
    redirect: interactive_45home_45designd5gJrPZMaWMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/interactive-home-design.vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesrDyk91cYgHMeta?.name ?? "my-favorites",
    path: my_45favoritesrDyk91cYgHMeta?.path ?? "/my-favorites",
    meta: my_45favoritesrDyk91cYgHMeta || {},
    alias: my_45favoritesrDyk91cYgHMeta?.alias || [],
    redirect: my_45favoritesrDyk91cYgHMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: indexr1S7aJVViyMeta?.name ?? "new-homes-homes-home",
    path: indexr1S7aJVViyMeta?.path ?? "/new-homes/homes/:home()",
    meta: indexr1S7aJVViyMeta || {},
    alias: indexr1S7aJVViyMeta?.alias || [],
    redirect: indexr1S7aJVViyMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUUsWr6p9lKMeta?.name ?? "new-homes-plans-plan",
    path: indexUUsWr6p9lKMeta?.path ?? "/new-homes/plans/:plan()",
    meta: indexUUsWr6p9lKMeta || {},
    alias: indexUUsWr6p9lKMeta?.alias || [],
    redirect: indexUUsWr6p9lKMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKUosattcsMeta?.name ?? "new-neighborhoods-location-community",
    path: indexOKUosattcsMeta?.path ?? "/new-neighborhoods/:location()/:community()",
    meta: indexOKUosattcsMeta || {},
    alias: indexOKUosattcsMeta?.alias || [],
    redirect: indexOKUosattcsMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: build_45on_45your_45lotR0IwpJX1z2Meta?.name ?? "our-homes-build-on-your-lot",
    path: build_45on_45your_45lotR0IwpJX1z2Meta?.path ?? "/our-homes/build-on-your-lot",
    meta: build_45on_45your_45lotR0IwpJX1z2Meta || {},
    alias: build_45on_45your_45lotR0IwpJX1z2Meta?.alias || [],
    redirect: build_45on_45your_45lotR0IwpJX1z2Meta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue").then(m => m.default || m)
  },
  {
    name: design_45processkJCmKNE8lFMeta?.name ?? "our-homes-design-process",
    path: design_45processkJCmKNE8lFMeta?.path ?? "/our-homes/design-process",
    meta: design_45processkJCmKNE8lFMeta || {},
    alias: design_45processkJCmKNE8lFMeta?.alias || [],
    redirect: design_45processkJCmKNE8lFMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue").then(m => m.default || m)
  },
  {
    name: financingNhcgg3oJQDMeta?.name ?? "our-homes-financing",
    path: financingNhcgg3oJQDMeta?.path ?? "/our-homes/financing",
    meta: financingNhcgg3oJQDMeta || {},
    alias: financingNhcgg3oJQDMeta?.alias || [],
    redirect: financingNhcgg3oJQDMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/our-homes/financing.vue").then(m => m.default || m)
  },
  {
    name: indexxGjJq6ngCrMeta?.name ?? "our-homes",
    path: indexxGjJq6ngCrMeta?.path ?? "/our-homes",
    meta: indexxGjJq6ngCrMeta || {},
    alias: indexxGjJq6ngCrMeta?.alias || [],
    redirect: indexxGjJq6ngCrMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQfPFmAQcDfMeta?.name ?? "privacy-policy",
    path: privacy_45policyQfPFmAQcDfMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQfPFmAQcDfMeta || {},
    alias: privacy_45policyQfPFmAQcDfMeta?.alias || [],
    redirect: privacy_45policyQfPFmAQcDfMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_930vqdcXDqfmMeta?.name ?? "promotions-slug",
    path: _91_46_46_46slug_930vqdcXDqfmMeta?.path ?? "/promotions/:slug(.*)*",
    meta: _91_46_46_46slug_930vqdcXDqfmMeta || {},
    alias: _91_46_46_46slug_930vqdcXDqfmMeta?.alias || [],
    redirect: _91_46_46_46slug_930vqdcXDqfmMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexV1X6UliDEuMeta?.name ?? "promotions",
    path: indexV1X6UliDEuMeta?.path ?? "/promotions",
    meta: indexV1X6UliDEuMeta || {},
    alias: indexV1X6UliDEuMeta?.alias || [],
    redirect: indexV1X6UliDEuMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: sandbox3Q6mSlygTEMeta?.name ?? "sandbox",
    path: sandbox3Q6mSlygTEMeta?.path ?? "/sandbox",
    meta: sandbox3Q6mSlygTEMeta || {},
    alias: sandbox3Q6mSlygTEMeta?.alias || [],
    redirect: sandbox3Q6mSlygTEMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: self_45guided_45home_45toursbJwMAHIHDZMeta?.name ?? "self-guided-home-tours",
    path: self_45guided_45home_45toursbJwMAHIHDZMeta?.path ?? "/self-guided-home-tours",
    meta: self_45guided_45home_45toursbJwMAHIHDZMeta || {},
    alias: self_45guided_45home_45toursbJwMAHIHDZMeta?.alias || [],
    redirect: self_45guided_45home_45toursbJwMAHIHDZMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93SiBjzEc3jPMeta?.name ?? "testimonials-testimonial",
    path: _91testimonial_93SiBjzEc3jPMeta?.path ?? "/testimonials/:testimonial()",
    meta: _91testimonial_93SiBjzEc3jPMeta || {},
    alias: _91testimonial_93SiBjzEc3jPMeta?.alias || [],
    redirect: _91testimonial_93SiBjzEc3jPMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: where_45we_45buildkExnSBHJueMeta?.name ?? "where-we-build",
    path: where_45we_45buildkExnSBHJueMeta?.path ?? "/where-we-build",
    meta: where_45we_45buildkExnSBHJueMeta || {},
    alias: where_45we_45buildkExnSBHJueMeta?.alias || [],
    redirect: where_45we_45buildkExnSBHJueMeta?.redirect,
    component: () => import("/codebuild/output/src563242301/src/consort-headless/nuxtapp/pages/where-we-build.vue").then(m => m.default || m)
  }
]